<template>
    <div class="nic-image">
        
        <img class="image" :src="imageUrl" alt="" @click="modalVisible = true">

        <transition name="fade4">
            <div class="modal" v-if="modalVisible">

                <span class="close-btn" @click="modalVisible = false">x</span>

                <img class="" :src="imageUrl" alt="">
            </div>
        </transition>

    </div>
</template>

<script>
export default {
    
    props: {
        
        imageUrl: {
            type: String,
            default: '',
        }
    },

    data () {
        return {
            modalVisible: false,
        }
    },
}
</script>


<style lang="scss">
    
    .nic-image {
        width: 174px;
        height: 174px;
        margin-right: 10px;

        .image {
            height: 100%;
            width: 100%;
        }
        
        .modal {
            
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($color: #000000, $alpha: .5);

            .close-btn {
                position: absolute;
                right: 30px;
                top: 30px;
                font-size: 50px;
                color: #fff;
            }

            img {
                height: auto !important;
                width: 100vw !important;
            }
        }
    }
</style>