import { timeFilter } from '@/utils/utils.js'
import * as api from './processing-detail-api.js'
import orderOperation from '@/mixins/order-operation.js'
import NicImage from '@/components/nic-image.vue'
import { Dialog, Popup, Picker  } from 'vant';
import AMapMixin from '@/mixins/map/map.js'

export default {
    mixins: [orderOperation, AMapMixin],
    components: {
        NicImage, 
        [Dialog.Component.name]: Dialog.Component,
        Popup,
        Picker,
    },
    data () {
        let self = this
        return {
            timeFilter,
            
            processingDetail: {},

            dialogVisible: false,   // dialog
            handOverPerson: {   // 移交人员
                cname: '',
                oppenId: '',
            },   

            popupVisible: false,    // popup
            popupList: [],

            plugin: [
                {
                    pName: 'Geolocation',
                    events: {
                        async init(o) {

                            let { longitude, latitude } = self.processingDetail

                            if (!longitude || !latitude) return

                            // o 是高德地图定位插件实例
                            await self.getCurrentPosition(o,async () => {

                                let mapObj = new AMap.Map("amapDemo", {resizeEnable: true});

                                let path = []
                                path.push([self.lng, self.lat]);
                                path.push([+longitude, +latitude]);

                                await self.getAddress({ lng: +longitude, lat: +latitude })

                                await new AMap.DragRoute(mapObj, path, AMap.DrivingPolicy.LEAST_FEE).search()
                            })()

                            document.getElementsByClassName('amap-geolocation-con')[0].addEventListener('click', self.getCurrentPosition(o))

                        },
                    }
                },
            ],

            load: false

        }
    },

    mounted() {
        
        let { creId } = this.$route.params

        this.processingDetail.creId = creId

        this.findByRepaireId()

        this.findRepairPersonnel()  // 查询人员列表

        setTimeout(() => {
            
            this.load = true
        }, 1000);
    },
    methods: {
        
        /* api */
        findByRepaireId () {
            api.findByRepaireId ({repaireId: this.processingDetail.creId}).then(data => {
                if (data) {
                    let d = data.data.resultObject
                    this.processingDetail = d
                }
            })
        },

        /* handle */
        /* dialog */
        openDialog () {  // 打开
            this.dialogVisible = true
            this.handOverPerson = {}
        },
        dialogConfirm () {  // 确认 - 移交对象

            let {
                cname: chandlerName,
                cwpId: chandlerId,

            } = this.handOverPerson

            let {
                creId
            } = this.processingDetail

            // mixins中
            this.editRepair({
                cstate: 1,
                creId,
                chandlerId,
                chandlerName,
            }, this.findByRepaireId)
        },

        /* popup */
        onConfirm (val) {   // 确认
            this.handOverPerson = val
            this.popupVisible = false
        },
    },
}